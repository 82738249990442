import React, { useMemo } from 'react';
import classnames from 'classnames';
import useTracking from 'components/Globals/Analytics';

import Typography from 'components/uiLibrary/Typography';
import LinkButton, { PrimaryButton } from 'components/uiLibrary/LinkButton';
import SeparatorList from 'components/uiLibrary/SeparatorList';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';

import { BASE_PAGE_ROUTES, ENTITY_TYPE, TP } from 'constants/index';

import usePageContext from 'utils/hooks/usePageContext';
import { usePaywallContext } from 'utils/hooks/usePaywallContext';
import { useDialogs } from 'utils/hooks/useDialogs';
import { useSubscription } from 'utils/hooks/useSubscription';
import { TRACK_EVENTS, VIEW_IN_CASTING_TOOL } from 'utils/tracking';
import { Trans, useTranslation } from 'src/i18n';

import classes from './CastingToolPromo.module.scss';
import { GOOGLE_OLD_TRACKING_SERVICES } from '../Analytics/constants';

const usePromoItems = entityType => {
  const items = useMemo(() => {
    switch (entityType) {
      case ENTITY_TYPE.MANAGER: {
        return [`${TP}.m_CONTACT`, `${TP}.m_ACCOUNT_TYPES_AGENT`, `${TP}.m_REPRESENTATIONDETAIL`];
      }
      default: {
        return ['ADVANCED_FILTERS_AND_CAREER_GRAPH', `${TP}.FN_FUTURE_REPERTOIRE`];
      }
    }
  }, [entityType]);

  return items;
};

const CastingToolPromo = ({
  trackingData,
  entityType,
  entity,
  asCTA = false,
  styles,
  onClick,
  showLoginCTA = false,
}) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');
  const track = useTracking();
  const promoItems = usePromoItems(entityType);
  const { navigate, permissions } = usePageContext();
  const subscription = useSubscription();
  const { setShowCastingToolSubscription } = usePaywallContext();
  const { isLoggedIn } = permissions;
  const { setIsLoginDialog, setIsOpenCheckout } = useDialogs();
  const tabNames = useMemo(() => promoItems?.map(item => t(item)), [promoItems, t]);

  const linkProps = useMemo(() => navigate.getLinkProps({ entityType, entity, pro: true, onlyLinkProps: true }), [
    entityType,
    entity,
    navigate,
  ]);

  const onClickHandler = event => {
    event.preventDefault();
    event.stopPropagation();

    const hasAccess = permissions?.castingToolPermissions?.hasAccess;

    track.click(
      {
        name: TRACK_EVENTS.CLICK,
        id: VIEW_IN_CASTING_TOOL,
      },
      GOOGLE_OLD_TRACKING_SERVICES,
    );

    if (onClick) {
      onClick(event);
    } else if (!isLoggedIn) {
      setIsLoginDialog({ isOpen: true });
    } else if (!hasAccess && !subscription) {
      setIsOpenCheckout(true);
      setShowCastingToolSubscription(true);
    } else {
      navigate.to(navigate.getLinkProps({ entityType, entity, baseRoute: BASE_PAGE_ROUTES.CASTING }));
    }
  };

  if (asCTA) {
    return (
      <LinkButton
        variant="orange"
        size="medium"
        styles={{
          root: classnames(classes.castingToolCTA, {
            [styles?.castingToolCta]: !!styles?.castingToolCta,
          }),
        }}
        {...linkProps}
        preventDefault
        isV4
        rightIcon={<SpriteIcon icon="chevron_right" size={16} />}
        trackingData={trackingData}
        onClick={onClickHandler}
        isLink
      >
        <Trans
          ns="NS_ENTITY_STUB_PAGE"
          i18nKey={`${TP}.FN_CASTING_TOOL_PROMO_CTA`}
          components={{
            casting_tool_cta: <Typography size="12" weight="medium" className={classes.castingToolLabel} />,
          }}
        />
      </LinkButton>
    );
  }

  return (
    <LinkButton
      variant="text"
      disableHover
      disableUnderline
      styles={{
        root: classnames(classes.link, {
          [classes.linkWithLoginCTA]: showLoginCTA,
          [styles?.root]: !!styles?.root,
        }),
      }}
      trackingData={trackingData}
      onClick={onClickHandler}
      {...linkProps}
      isLink
      preventDefault
    >
      <div className={classes.container}>
        <Typography size={showLoginCTA ? 16 : 12} weight="bold">
          <Trans
            ns="NS_ENTITY_STUB_PAGE"
            i18nKey={`${TP}.FN_VIEW_CASTING_TOOL_PROMO`}
            components={{
              casting_tool: <Typography size={showLoginCTA ? 16 : 12} weight="bold" />,
            }}
          />
        </Typography>

        <div className={classes.menuItems}>
          <Typography size="12" className={classes.menuItem} truncate>
            <SeparatorList data={tabNames} />
            {!showLoginCTA && <SpriteIcon icon="chevron_right" size={16} className={classes.icon} />}
          </Typography>
        </div>
      </div>
      {showLoginCTA && (
        <PrimaryButton size="medium" rightIcon={<SpriteIcon icon="chevron_right" size={16} />}>
          {t(`${TP}.LOGIN_REGISTER`)}
        </PrimaryButton>
      )}
    </LinkButton>
  );
};

export default CastingToolPromo;
